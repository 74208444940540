<template>
  <div class="newsListBox">
    <div class="breadcrumbOutside">
      <div class="breadcrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/nanfanNews' }">今日南繁</el-breadcrumb-item>
          <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="mainBox">
      <el-row>
        <el-col :span="17">
          <div class="newsListBox_left">
            <div class="nbl_block" v-show="showNewsList">
              <div class="nbi_title">
                <div class="nbit_font">海南要闻</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in hainanNewsList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(0,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
            <div class="nbl_block" v-show="showpolicyList">
              <div class="nbi_title">
                <div class="nbit_font">最新政策</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in policyList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(1,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
            <div class="nbl_block" v-show="shownoticeList">
              <div class="nbi_title">
                <div class="nbit_font">通知公告</div>
              </div>
              <div class="nbit_Detail">
                <el-row class="nbit_Detail_row" v-for="(item,index) in noticeList" :key="index">
                  <el-col class="nbit_Detail_col" :span="18" :title="item.title">
                    <p @click="toDetail(2,item.id)">{{item.title}}</p>
                  </el-col>
                  <el-col
                    class="nbit_Detail_col nbitd_rc_time"
                    :span="6"
                    :title="item.title"
                  >{{item.time}}</el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="newsPageBox">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="datalength"
              :page-size="pageSize"
              :current-page.sync="pageNum"
              @current-change="paginationChange"
            ></el-pagination>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="newsListBox_right">
            <div class="nbr_block" v-show="!showNewsList">
              <div class="nbr_block_inside">
                <div class="nbi_title">
                  <div class="nbit_font">海南要闻</div>
                </div>
                <div class="nbit_Detail">
                  <el-row
                    class="nbr_row"
                    v-for="(item,index) in hainanNewsListShort"
                    :key="index"
                    @click.native="toDetail(0,item.id)"
                  >
                    <el-col :span="6">
                      <img class="nbr_img" :src="item.url" alt />
                    </el-col>
                    <el-col class="nbr_hidden" :span="18">
                      <p class="nbr_p">{{item.title}}</p>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="nbr_block" v-show="!showpolicyList">
              <div class="nbr_block_inside">
                <div class="nbi_title">
                  <div class="nbit_font">最新政策</div>
                </div>
                <div class="nbit_Detail">
                  <el-row
                    class="nbr_row"
                    v-for="(item,index) in policyListShort"
                    :key="index"
                    @click.native="toDetail(1,item.id)"
                  >
                    <el-col :span="6">
                      <img class="nbr_img" :src="item.url" alt />
                    </el-col>
                    <el-col class="nbr_hidden" :span="18">
                      <p class="nbr_p">{{item.title}}</p>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class="nbr_block" v-show="!shownoticeList">
              <div class="nbr_block_inside">
                <div class="nbi_title">
                  <div class="nbit_font">通知公告</div>
                </div>
                <div class="nbit_Detail">
                  <el-row
                    class="nbr_row"
                    v-for="(item,index) in noticeListShort"
                    :key="index"
                    @click.native="toDetail(2,item.id)"
                  >
                    <el-col :span="6">
                      <img class="nbr_img" :src="item.url" alt />
                    </el-col>
                    <el-col class="nbr_hidden" :span="18">
                      <p class="nbr_p">{{item.title}}</p>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import "../../../src/pages/nanfanNews/nanfanNews.css";
export default {
  data() {
    return {
      nowNum: "",
      datalength: 0,
      pageNum: 1,
      pageSize: 12,
      pageSizeShort: 4,
      columnIds: {
        news: "d1707e23348143fb99537c9bddffcdb3", // 最新要闻
        newsX: "3373f13e83b74f12a213a53db20a6657", // 最新政策
        notice: "abb9308a63f64190b55633b83cc27e12" // 通知公告
      },
      hainanNewsList: [],
      policyList: [],
      noticeList: [],
      hainanNewsListShort: [],
      policyListShort: [],
      noticeListShort: [],
      showNewsList: true,
      showpolicyList: true,
      shownoticeList: true
    };
  },
  async created() {
    await this.getData("news", this.pageSize);
    await this.getData("newsX", this.pageSize);
    await this.getData("notice", this.pageSize);
    await this.getData("news", this.pageSizeShort);
    await this.getData("newsX", this.pageSizeShort);
    await this.getData("notice", this.pageSizeShort);
  },
  mounted() {
    this.nowNum = this.$route.query.num;
    if (this.nowNum == 1) {
      this.showNewsList = true;
      this.showpolicyList = false;
      this.shownoticeList = false;
    }
    if (this.nowNum == 2) {
      this.showNewsList = false;
      this.showpolicyList = true;
      this.shownoticeList = false;
    }
    if (this.nowNum == 3) {
      this.showNewsList = false;
      this.showpolicyList = false;
      this.shownoticeList = true;
    }
  },
  methods: {
    toDetail(idx, id) {
      this.$router.push({
        path: "/home/news/detail",
        query: {
          idx: idx,
          id: id,
          prev: "newsList"
        }
      });
    },
    paginationChange(val) {
      this.pageNum = val;
      if (this.nowNum == 1) {
        this.getData("news", this.pageSize);
      }
      if (this.nowNum == 2) {
        this.getData("newsX", this.pageSize);
      }
      if (this.nowNum == 3) {
        this.getData("notice", this.pageSize);
      }
    },
    async getData(opt, pageSize) {
      const api = this.$fetchApi.dynamicNewsList.api;
      const data = await this.$fetchData.fetchPost(
        {
          pageNum: this.pageNum,
          pageSize: pageSize,
          columnId: this.columnIds[opt]
        },
        api,
        "json"
      );
      if (data.code === "200") {
        let nowData = data.result.rows;
        this.datalength = data.result.total;
        if (pageSize == 12) {
          if (opt == "news") {
            this.hainanNewsList = [];
            for (let i in nowData) {
              this.hainanNewsList.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
          if (opt == "newsX") {
            this.policyList = [];
            for (let i in nowData) {
              this.policyList.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
          if (opt == "notice") {
            this.noticeList = [];
            for (let i in nowData) {
              this.noticeList.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
        } else {
          if (opt == "news") {
            for (let i in nowData) {
              this.hainanNewsListShort.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
          if (opt == "newsX") {
            for (let i in nowData) {
              this.policyListShort.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
          if (opt == "notice") {
            for (let i in nowData) {
              this.noticeListShort.push({
                url: nowData[i].mainImgUrl,
                title: nowData[i].title,
                time: nowData[i].releaseDate,
                id: nowData[i].informationId
              });
            }
          }
        }
      }
    }
  }
};
</script>
<style scoped>
</style>
